import axios from 'axios';
import QS from 'qs';
import { Loading } from 'element-ui';

axios.defaults.timeout = 100000;
axios.interceptors.request.use(
    config => {
        // 每次发送请求之前判断vuex中是否存在token
        // 如果存在，则统一在http请求的header都加上token，这样后台根据token判断你的登录情况
        // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
        // const token = store.state.token;
        // token && (config.headers.Authorization = token);
        // debugger
        // config['url'] = 'https://www.diqrng.com/' + config['url']
        config['url'] = 'https://www.randomnessbeacon.com/' + config['url']
        // config['url'] = 'http://sjscs.qilushop.cn' + config['url']
        // config['url'] = 'http://sjs.yw.cn' + config['url']
        return config;
    },
    error => {
        return Promise.error(error);
    })


// 响应拦截器
axios.interceptors.response.use(
    response => {
        // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
        // 否则的话抛出错误
        if (response.status === 200) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    });

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params
        }).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err.data)
        })
    });
}



/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params, loadingDomList = []) {
    const loadingInstanceList = (Array.isArray(loadingDomList) ? loadingDomList : [loadingDomList]).map(selector => {
        return Loading.service({
            target: selector,
            text: "加载中..."
        })
    })

    return new Promise((resolve, reject) => {
        let userToken = this.$cookie.get('userToken')
        let headers = {
            'x-token': userToken,
            'lang': localStorage.getItem('locale') || 'zh'
        }
        axios.post(url, QS.stringify(params),
            {
                headers: headers
            })
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            }).finally(() => {
                loadingInstanceList.map(o => o && o.close());
            })
    });
}
