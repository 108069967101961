export default {
    zh: {
        'title': '随机数信标是一种由可信第三方提供公共随机数的服务。简单而言,信标需要按特定的时间间隔持续广播满足一定安全性要求的随机数。为了保证公共随机数服务的可信性,信标需要具备以下特点：',
        'usability': '可用性',
        'usabilityIntroduce': '合法用户可以成功访问该公共服务',
        'unpredictability': '不可预测性',
        'unpredictabilityIntroduce': '没有人能提前预知将产生的随机数',
        'autonomy': '自治性',
        'autonomyIntroduce': '任何人都不能修改已产生的随机数',
        'consistency': '一致性',
        'consistencyIntroduce': '一组合法用户可以获得相同的随机数',
        'verifiability': '可验证性',
        'verifiabilityIntroduce': '每个人都可以对先前产生的随机数进行验证',
        'introduce': '在这些內容中,随机数的不可预測性是决定随机数信标安全性和可信程度的核心。为满足这一要 使用当前理论已知的安全等级最高的随机数发生器——设备无关量子随机数发生器 基于中国科学技术大学近期实现的当前最高速率设备无关量子随机数发生器,这里提供世界上第一个使用此类随机数发生器的信标服务',
        'title1': '（1）信标服务使用简介',
        'introduce_a1': '信标服务每隔60秒将广播新产生的具有均匀随机性的512位比特长度的随机数块。每个随机数块值都带有时间戳和数字签名，并且包含前一个随机数块的哈希值，从而防止对已生成数据的篡改。信标将持续产生这样的数据，并在网站上实时公布。合法用户可于网站上获取特定时刻产生的随机数块，并获取相关的哈希值、签名等信息，亦可对先前数据进行验证。',
        'introduce_a2': '警告：信标服务产生的随机数不可用于密钥',
        'title2': '(2)信标服务熵源一器件无关量子随机数发生器',
        'introduce_b1': '传统的基于电路噪声等过程的物理随机数发生器，由于难以严格量化其产生的随机性，难以达到信标服务的安全性要求。常见的商用量子随机数发生器的随机性可以被很好量化，但安全性依赖于对量子器件性能的标定，存在被恶意攻击的安全隐患。\n' +
            '为克服上述困难，这里采用了器件无关量子随机数发生器。该随机数发生器基于无漏洞的贝尔实验，可以在不相信所用量子器件的情况下，仅通过产生的数据对安全性进行自检测，确保熵源满足信标要求。这里的信标服务基于中科大团队的系列实验工作，使用了当前具有最高单光子探测效率的超导探测器，利用新型量子概率估计方案对生成随机数的安全性进行严格信息论安全分析，并结合使用了快速傅里叶变换的抗量子强随机数析取器，达到了每60秒实时产生均匀分布的512比特不可预测随机数的性能。',
        'introduce_b2': '器件无关量子随机数发生器基于无漏洞的贝尔实验。典型的贝尔实验涉及多个空间上分离的测量装置，且每个测量装置有多种可能的测量设置。如果这些测量装置的测量结果具有某些特定的关联，则可以证明“鬼魅般的相互作用”——量子纠缠存在于这些装置之间，并且测量结果可以用于信息论安全的随机数产生。为保证实验不存在安全性漏洞，其中一个关键的要求是不同测量装置的测量过程是相互独立的。为保证这一条件，该服务所使用的器件无关量子随机数发生器通过将测量装置分隔超过180米，并精确控制测量过程的时间，确保它们具有类空分隔的因果关系，从而通过相对论保证了测量过程的独立性。在该服务中，还克服了包括探测器效率漏洞、输入随机性漏洞等安全性漏洞，确保了产生随机数的安全性。',

    },
    en: {
        'title': 'Randomness beacon is a service that provides public random numbers by a trusted third party. A randomness beacon is required to broadcast random numbers at a regular time that meet certain security requirements. To guarantee that the public random number service can be trusted, a randomness beacon needs to be equipped with the following properties:',
        'usability': 'Usability',
        'usabilityIntroduce': 'an honest user should have access to the public random numbers successfully',
        'unpredictability': 'Unpredictability',
        'unpredictabilityIntroduce': 'no one can predict the random numbers before they are made available by the source. Particularly, no one can predict the possible value of the next random number based on historical data, which means it has Markov property',
        'autonomy': 'Autonomy',
        'autonomyIntroduce': 'the source is resistant to attempts to alter the distribution of the random numbers. The past value of a random number cannot be modified by anyone. This property also requires that the random numbers provided by the beacon are not biased, which can be advantageous to an adversary',
        'consistency': 'Consistency',
        'consistencyIntroduce': 'a set of users can receive the same random numbers by appropriate means',
        'verifiability': 'Verifiability',
        'verifiabilityIntroduce': 'everyone can verify the value and consistency of the generated random number after it is made available',
        'introduce': 'Among these requirements, unpredictability is the essence that determines the security and trustiness of randomness beacon. For this requirement, the random number generator of the highest level of security --- device-independent quantum random number generator (DIQRNG) --- can be used. Here, we provide the first beacon service using DIQRNG as the entropy source worldwide. This service is based on the device-independent quantum random number generator with the highest generation rate, recently realised by USTC (Shanghai).',
        'title1': '(1) Brief usage introduction',
        'introduce_a1': 'The beacon service broadcasts a block of 512 fresh random bits that are uniformly distributed every 60 seconds. Every block of random numbers has a timestamp and a digital signature and contains the hash value of the last block to avoid attempts to alter the generated statistics. The beacon shall continuously generate statistics of this form and broadcast on the website in real-time. Valid users can assess a block of random numbers at a specific time and acquire the information of hash value, digital signature, etc. Previous data can also be certified.',
        'introduce_a2': 'Warning: the random numbers generated by the beacon service cannot be used as private keys!',
        'title2': '(2) Entropy source of beacon service --- device-independent quantum random number generator',
        'introduce_b1': 'Conventional physical random number generators based on chaotic processes (e.g., the noise in electric circuits) hardly meet the security standards of the beacon service, as it is difficult to rigorously quantify the randomness of their outputs. Output randomness from common commercial quantum random number generators can be well quantified. However, their security relies on the characterization of the quantum devices, which leaves the possibility of being hacked.\n' +
            'To overcome these problems, this beacon service adopts a device-independent quantum random number generator (DIQRNG). This random number generator is based on the loophole-free Bell violation test, which allows the security self-testing independent of the quantum devices and from only the output statistics. The entropy source based on DIQRNG meets the unpredictability requirement of a randomness beacon. The beacon service here is based on a series of experimental works by USTC (Shanghai). The superconducting detectors with the highest single-photon detection efficiencies are used, and a rigorous information-theoretic security analysis based on quantum probability estimation is adopted for the randomness evaluation. Combined with the quantum-proof strong randomness extractor using fast Fourier transformation, the beacon service can generate 512 bits of uniformly distributed random number every 60 seconds in real-time.',
        'introduce_b2': 'DIQRNG is based on loophole-free Bell violation experiments. A typical Bell test involves multiple space-like separated measurement devices, each of which has more than one measurement setting. If certain correlations exist in the measurement results, it can be proved that “the spooky interaction” --- quantum entanglement --- exists between these devices, and the measurement results can be used for generating information-theoretically secure random numbers. To close all security loopholes in the experiment, one key requirement is that the measurements of different devices need to observe a non-signaling condition. The DIQRNG of this beacon service separates the measurement devices by more than 180 meters, and times of the measurement processes are accurately controlled. These measures guarantee that the measurement events have a space-like separated causal order and hence independent of the measurement processes of different parties. In this service, other security loopholes including the detector efficiency loophole, input randomness loopholes are ruled out, too. ',


    }
}