export default {
    zh: {
        'detail': '个人资料',
        'history': '下载历史',
        'api': 'api接口调用',
        'information': '基本信息',
        'userInfo': '用户名',
        'password': '原始密码',
        'newPassword': '新密码',
        'confirmPassword': '确认密码',
        'email': '邮箱',
        organization: '所属机构',
        usage: '用途',
        edit: '修改',
        Verifica: '验证码',
        SVerifica: '发送验证码',
        cancel: '取消',
        confirm: '确 认',
        timeFrame: '时间范围',
        selectTime: '选择时间',
        search: '查询',
        downLoadTime: '下载时间',
        randomCreateTime: '随机数生成时间',
        downLoad: '下载',
        randomDetail: '随机数详情',


    },
    en: {
        'detail': 'Personal Data',
        'history': 'Download History',
        'api': 'API',
        'information': 'Essential Information',
        'userInfo': 'Userinfo',
        'password': 'OriginalPassword',
        'confirmPassword': 'ConfirmPassword',
        'newPassword': 'NewPassword',
        'email': 'Email',
        organization: 'Organize',
        usage: 'Usage',
        edit: 'Edit',
        Verifica: 'verify',
        SVerifica: 'Send verify',
        cancel: 'Cancel',
        confirm: 'Confirm',
        timeFrame: 'Time frame',
        selectTime: 'Select time',
        search: 'Search',
        downLoadTime: 'DownLoad time',
        randomCreateTime: 'Random create time',
        downLoad: 'Download',
        randomDetail: 'Random detail',


    }
}
