export default {
    zh: {
        releaseTime: '发布时间',
        source: '来源',
    },
    en: {
        releaseTime: 'Release time',
        source: 'source',
    }
}
