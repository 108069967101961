export default {
    zh: {
        'background': '背景介绍',
        'backgroundContent1': '现代的随机数产生设备通常是基于软件算法的随机数产生器，或基于热噪声的随机数产生器。这一类装置产生的随机数实际上基于确定性过程，属于伪随机数。量子力学的发展从根本上改变了这一局面。量子力学的基本原理具有经典物理中所不具有的内禀随机性，可以用于产生真正的随机数。例如，目前商用量子随机数产生器通常是基于测量量子叠加态的量子随机数产生器、测量量子到达时间的随机数产生器和真空涨落噪声的量子随机数产生器等。但是，这一系列随机数产生器高度依赖所使用的器件，窃听者可以利用设备的漏洞进行攻击、窃取甚至操控随机数的产生，从而无法保证随机数的安全性。',
        'backgroundContent2': '器件无关的量子随机数产生器利用无漏洞贝尔不等式检验从根本上避免了这一系列问题。这种随机数产生装置利用量子物理特有的性质，通过检测无漏洞贝尔不等式，可以不对器件做任何假设地验证是否存在量子纠缠态，而量子纠缠态可以作为产生量子随机性的最基本资源，用来进行随机数的生成。通过测量无漏洞贝尔不等式的破坏值，可以严格地估计系统产生随机数速率，并最终提取生成安全的随机数。由于器件无关的量子随机数产生器的安全性仅仅依赖于贝尔不等式的破坏，对采用的器件的来源及工作原理不作要求，因此被认为是安全性最高的随机数产生装置。',
        "applications": '应用',
        "applicationsContent1": '随机数在科学研究和日常生活中有着重要而广泛的应用：在工程计算中，常常遇到无法通过解析分析的问题需要通过数值模拟计算进行模拟，例如汽车、飞机等外形的气动设计需要通过对大量气体分子进行蒙特卡罗模拟以便在设计时不用真正建造模型而模拟现实情况；在医学、生物学中常常需要随机地在统计总体中抽取有代表性的样本，将实验动物分配到不同的试验组中; 在游戏、博彩、人工智能等领域，常常需要使用不可预测的随机数控制系统下一步的状态；在通信安全、现代密码学等领域，为了保证通信的安全与保密，需要不可预测的随机数作为安全性的基础。',
        "applicationsContent2": '我们致力于产生满足各类应用需求的随机数，欢迎广大用户提出需求与建议（邮箱：qrng@ustc.edu.cn）。',
        'introduction': "器件无关量子随机数产生系统",
        'introductionContent1': "贝尔不等式检验由英国物理学家约翰•贝尔在1964年提出[J. S. Bell, Phys. Physique Fizika 1, 195(1964)]。考虑对两粒子系统进行测量的结果，他定义了一个可观测量，基于经典物理框架下该值都不大于2。而在量子力学框架下，可以计算得出其最大值可以到2√2。因此，如果可观测量的值大于2，那么只能在量子力学框架下才能解释这个结果，也就是说这两个粒子之间是纠缠的。",
        'introductionContent2': "器件无关的随机数产生系统是基于无漏洞贝尔检验实验搭建的。如图1所示，在系统的每个工作周期中，纠缠源产生一对纠缠光子并分发到两侧（Alice和Bob处），在每一侧分别用随机的测量基矢进行测量，并用单光子探测器探测。通过随机数提取器，将安全有效的随机数加以提取并上传到服务器供用户使用和下载。其中，由实验中得到贝尔不等式的破坏值可以计算安全的随机数的产率，而对最终提取的随机数进行NIST随机数统计检验，可以验证随机数的随机性(http://csrc.nist.gov/groups/ST/toolkit/rng/stats_tests.html)。",
        'introductionContent3': "图1.随机数产生系统装置图。我们采用基于自发参量下转换过程的Sagnac环式纠缠源产生纠缠光子对，纠缠光子对分别通过单模光纤分发至两个探测端。探测端利用普克尔盒的非线性特性，输入的随机数通过改变加载在普克尔盒上的电压，从而随机选择基矢进行测量，测量的结果使用超导纳米线单光子探测器探测，此时得到的数据我们称为原始数据。原始数据经过提取后，即可得到最终的器件无关的量子随机数。我们将随机数上传至服务器提供给用户进行获取与下载。(http://csrc.nist.gov/groups/ST/toolkit/rng/stats_tests.html)。",
        'introductionContent4': "图2. 随机数产生系统各事件的时空关系图。横坐标表示各事件所在的空间距离，纵坐标表示事件发生的相对时间，Source表示纠缠源，Alice和Bob分别是两个测量端。",
        'introductionContent4-1': "表示纠缠源产生纠缠光子对事件所需要的时间，",
        'introductionContent4-2': "表示产生用于基矢选择的随机数所需要的时间，",
        'introductionContent4-3': "表示随机数从输出到输入普克尔盒所需要的时间，",
        'introductionContent4-4': "表示普克尔盒从接收随机数事件到进行相应基矢测量事件所需要的时间，",
        'introductionContent4-5': "表示，超导纳米线单光子探测器从接收光子事件到输出测量结果事件所需要的时间。我们的系统中，",
        'introductionContent4-6': "。Alice和Bob至Source的空间距离分别为93m和90m，Alice和Bob与Source传输纠缠光子对的单模光纤长度分别为132m和119m。图中阴影区域分别表示对应事件的未来光锥（从对应事件点开始，在未来时间里，该事件以光速或者小于光速传播所涉及的空间范围）。",
        'introductionContent5': "贝尔不等式检验过程中存在的漏洞使得经典态也有办法使不等式得到破坏，为了得到安全的量子随机数，我们关闭了以下2个漏洞:",
        'introductionContent6': "1.探测效率漏洞：实际情况中，由于器件的不完美，系统的探测效率不可能达到100%，如果探测效率很低，即使探测到的光子能够破坏贝尔不等式也不能说明所有的光子能够破坏贝尔不等式。在我们的系统中，整体探测效率达到81%，超过了理论阈值 [P. H. Eberhard, Phys. Rev. A 47, R747 (1993)]，所以关闭了探测效率漏洞。",
        'introductionContent7': "2.局域性漏洞：如果两个纠缠的光子距离太近，对贝尔不等式的违背，有可能是靠某个不大于光速的通讯通道来实现的，而非源自量子理论非局域性。为了关闭这个漏洞，我们需要把两个光子分发得足够远，使得Alice和Bob对光子的测量是相互独立的，不能受到彼此的影响，在我们的系统中，Alice (Bob) 对光子的测量事件TM1 (TM2）在Bob（Alice）的测量事件蓝色光锥（红色光锥）之外，同时Alice（Bob）基矢选择事件TQRNG1 (TQRNG2)在纠缠源产生的事件光锥（绿色光锥）之外，这样可以确保这种独立性，从而关闭了局域性漏洞（如图2所示）。",
    },
    en: {
        'background': 'Background',
        'backgroundContent1': 'Modern random number generators are generally based on software algorithms or thermal noise, which are based on the deterministic process and belong to the pseudo random number generator. The development of quantum mechanics fundamentally changed the situation. The fundamental principles of quantum mechanics decide inherent randomness not found in classical physics and can be used to generate a truly random number. For example, current commercial quantum random number generators are usually based on the measurement of quantum superposition state, the measurement of quantum arrival time, and vacuum fluctuation noise. However, this kind of random number generator is highly dependent on the devices, and eavesdroppers can attack, steal or even control the generation of random numbers by exploiting the vulnerabilities of the devices, thus the security of random numbers cannot be guaranteed.',
        'backgroundContent2': 'The device-independent quantum random number generator can avoid these problems by using a loophole-free test of the Bell inequality. This random number generator makes use of the properties of quantum physics to verify the violation of loophole-free Bell inequality and to make sure the existence of quantum entanglement without any other assumptions. Quantum entanglement can be used as the most basic resource to generate quantum randomness and so that to generate truly random numbers. The violation of loophole-free Bell inequality can be used to estimate the rate of generation, then which can be extracted to produce the secure random numbers. Since the security of the device-independent quantum random number generator only depends on the violation of Bell inequality and has no requirement on the source and the devices, it is considered to be the most secure random number generator.',
        'applications': "Applications",
        'applicationsContent1': "Random numbers have widespread use in research and life. In engineering calculation, problems that do not have analytical solutions always need to be simulated by numerical simulation. For example, the shape of car and airplane without an existed model should be designed by the Monte-Carlo Simulation of lots of gas molecules; in medicine and biology, scientists always need to choose samples from the statistical ensemble randomly, or assign experimental animals to different experimental groups randomly; in the fields of games, gambling, and artificial intelligence, it is often necessary to use unpredictable random numbers to control the next step of the system; in the field of communication security, modern cryptography and so on, we also need unpredictable random numbers to ensure the security and confidentiality of communications, which are the basis of the security.",
        'applicationsContent2': "We are working at generating random numbers that meet the needs of various applications. You are welcome to submit your needs and suggestions to the email qrng@ustc.edu.cn.",
        'introduction': "The System of Device-Independent Quantum Random Number Generator (DIQRNG) ",
        'introductionContent1': "The System of Device-Independent Quantum Random Number Generator (DIQRNG) Bell's inequality was proposed by British physicist John Bell in 1964 [J. S. Bell, Phys. Physique Fizika 1, 195(1964)]. Taking into account the results of the measurement of a two-particle system， he defined an observable which cannot be greater than 2 based on the classical physics framework. But under the framework of quantum mechanics, it can be concluded that the maximum value of the observable is 2√2. Therefore, if the observable measurement is greater than 2, the result can only be explained under the quantum mechanical framework, which means the two particles are entangled.\n",
        'introductionContent2': "The device-independent random number generation system is based on the loophole-free Bell test experiment. As shown in Fig.1, in each work cycle of the system, the entanglement source generates a pair of entangled photons and distributes them to both sides (Alice and Bob). On each side, photons are measured in random measurement basis vectors and detected by a single photon detector. Through the random number extractor, safe and effective random numbers are extracted and uploaded to the server for users to use and download. Among them, the damage value of Bell's inequality obtained from the experiment can be used to calculate the yield of safe random numbers, and the NIST random number statistical test on the final extracted random numbers can verify the randomness of random numbers(http://csrc.nist.gov/groups/ST/toolkit/rng/stats_tests.html).",
        'introductionContent3': "Fig. 1 The setup of the random number generation. We use spontaneous parameter down conversion in a Sagnac loop to generate entangled photon pairs, which will then be distributed in opposite directions to Alice’s and Bob’s measurement stations through the single-mode fiber. Using the nonlinearity of the Pockels cell, we change the voltage applied on the Pockels cell according to the input random numbers, thereby randomly selecting the basis vector for measurement. The measurement results are detected by a superconducting nanowire single photon detector. The data obtained at this time is called the raw data, from which device-independent random numbers are extracted. We will upload the random numbers to the server for clients to obtain and download.(http://csrc.nist.gov/groups/ST/toolkit/rng/stats_tests.html).",
        'introductionContent4': "Fig. 2 Space–time diagram for events in the system. The horizontal axis shows the spatial distance of each event, and the vertical axis shows the relative time of them. ",
        'introductionContent4-1': " is the time taken to generate a pair of entangled photons.",
        'introductionContent4-2': "are the times required to generate random bits to switch the Pockels cells.",
        'introductionContent4-3': " are the lengths of time between the random bits being generated and delivered to the Pockels cells.",
        'introductionContent4-4': " are the waiting times for the Pockels cells to be ready to perform state measurements after receiving the random bits.",
        'introductionContent4-5': " are the times taken by the superconducting nanowire single-photon detectors to output electronic signals. For ",
        'introductionContent4-6': ", we place Alice’s and Bob’s measurement stations on opposite sides of the source at distances of 93 m and 90 m, respectively. The effective optical length between Alice’s (Bob’s) station and the source is 132 m (119 m). This arrangement ensures no signaling between relevant events in the experiment. The shaded areas are the future light cones for the source, Alice and Bob, i.e. in the future time, the space involved when the event travel at or less than the speed of light.",
        'introductionContent5': "The loopholes in the test of Bell's inequality make it possible for classical states to destroy the inequality. To obtain true quantum random numbers, we close the following two loopholes.",
        'introductionContent6': "1.Detection loophole. In practice, the efficiency of the system cannot reach 100% due to the imperfection of the device. If the efficiency is too low, even if the detected photons can break Bell's inequality, it does not mean that all photons can break Bell's inequality. In our system, the efficiency reached 81%, exceeding the theoretical threshold [P. H. Eberhard, Phys. Rev. A 47, R747 (1993)], so the detection loophole was closed.",
        'introductionContent7': "2.Locality loophole. If two entangled photons are too close, the violation of Bell's inequality could be caused by a communication rather than quantum non-locality, whose speed is less than the speed of light. To close the loophole, we need to distribute the two photons far enough to make sure Alice’s (Bob’s) measurement will not be affected by each other. In our system, Alice’s (Bob’s) measurement event TM1(TM2) is out of the blue light cone (red light cone) of Bob’s (Alice’s) measurement event. Meanwhile, Alice’s (Bob’s) vector selection event TQRNG1 (TQRNG2) is out of the green light cone of the entanglement generation event, so that we can ensure the independence of them. Thus, the locality loophole is closed (as shown in Fig. 2).",
    }
}