// work with node-version:10.15.3 npm-version:6.4.1
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/css/common.css'
import initComponent from '@/utils/initComponent.js'
Vue.use(initComponent)
import header from "./components/header"
import footer from "./components/footer"
import Cookies from 'js-cookie'
Vue.prototype.$cookie = Cookies;
Vue.component("my-header", header);
Vue.component("my-footer", footer);
Vue.use(ElementUI);
import { get, post } from '@/utils/request';
import * as common from '@/utils/common'
Vue.prototype.$com = common
// debugger
Vue.prototype.$get = get;
Vue.prototype.$post = post;
Vue.config.productionTip = false

Vue.prototype.$bus = new Vue();


import i18n from './i18n/i18n'

new Vue({
    i18n,
    router,
    store,
    render: h => h(App)
}).$mount('#app')
