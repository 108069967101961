import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index.vue'
Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'index',
    component: index
},
{
    path: '/aqfx',
    name: 'aqfx',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
        import( /* webpackChunkName: "about" */ '@/views/aqfx.vue')
},
{
    path: '/new-details',
    name: 'new-details',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
        import( /* webpackChunkName: "about" */ '@/views/new-details.vue')
},
{
    path: '/news',
    name: 'news',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
        import( /* webpackChunkName: "about" */ '@/views/news.vue')
},
{
    path: '/application',
    name: 'application',
    component: () =>
        import('@/views/application.vue')
},
{
    path: '/download',
    name: 'download',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/download.vue')
},
{
    path: '/summarize',
    name: 'summarize',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/summarize.vue')
},
{
    path: '/acquire',
    name: 'acquire',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/acquire.vue')
},
{
    path: '/history',
    name: 'history',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/history.vue')
},
{
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
        import( /* webpackChunkName: "about" */ '../views/login.vue')
},
{
    path: '/member',
    name: 'member',
    component: () => import('../views/member.vue')
},
{
    path: '/downloadService',
    name: 'downloadService',
    component: () => import('../views/downloadService.vue')
}
]

const router = new VueRouter({
    routes
})
export default router
