<template>
  <div>
    <el-carousel height="350px">
      <el-carousel-item v-for="(item,index) in list" :key="index">
        <div style="height:350px;color:white;padding-top:100px"
             :style="'background:url('+item.url+') no-repeat 0px 0px'">
          <h3 class="small">{{ $t('lunbo.'+item.title) }}</h3>
          <p style="padding-top:50px;width: 90%;margin: 0 auto;font-size: 14px;line-height:20px;">{{ $t('lunbo.'+item.content) }}</p>
          <p style="width: 90%;margin: 0 auto;font-size: 14px;line-height:20px;">{{ $t('lunbo.'+item.contents) }}</p>
          <p style="width: 90%;margin: 0 auto;font-size: 14px;line-height:20px;">{{ $t('lunbo.'+item.content1) }}</p>
        </div>

      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          title: 'title',
          content: 'content',
          contents: 'contents',
          content1: 'content1',
          url: 'images/banner1.png'
        },
      ]
    }
  },
  created() {
    if (this.$route.path === '/') {
      this.list.push({
        title: 'title1',
        content: 'content2',
        content1: 'content4',
        contents: 'content3',
        url: 'images/banner2.png'
      })
    }
  }
}
</script>
