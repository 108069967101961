export default {
    zh: {
        'pre_title1': '本网站为用户提供',
        'after_title1': '器件无关的量子随机数',
        'content': '利用量子纠缠的物理性质，通过进行无漏洞的贝尔检验，可产生基于量子力学内禀随机性的随机数，即器件无关的量子随机数，该随机数的安全性将不依赖于采用的器件。这种随机数产生装置被认为是安全性最高的随机数产生装置。本网站所提供的器件无关量子随机数均通过NIST随机数测试标准，并且可追溯产生时间，用户可证明其在产生前具有不可预测性。\n',
        'sjsInfo': '最新信标脉冲中随机数信息',
        'more': '了解更多',
        'newsIntroduction': '新闻资讯抢先看，创新与进步只为给您带来卓越体验',
    },
    en: {
        'pre_title1': 'This website provides',
        'after_title1': ' device independent quantum random numbers for users.',
        'content': 'For the physical properties of quantum entanglement and the bell test without loopholes, the random numbers based on the intrinsic randomness of quantum mechanics can be generated, that is, the device-independent quantum random numbers. The security of the random numbers will not depend on the device used. This kind of random number generation device is considered to be the most secure random number generation device. The device-independent quantum random numbers provided in this website all pass the NIST randomness tests and can be traced back to the generation time, which is proven to be unpredictable before generation.\n',
        'sjsInfo': 'Random number information in the latest beacon pulse',
        'more': 'More',
        'newsIntroduction': 'News and information first, innovation and progrxess only bring you excellent experience',
    }
}