function add0(m) { return m < 10 ? '0' + m : m }

function timeFormat(shijianchuo) {
    if (!shijianchuo) {
        return shijianchuo
    }
    //shijianchuo是整数，否则要parseInt转换
    var time = new Date(shijianchuo);
    var y = time.getFullYear();
    var m = time.getMonth() + 1;
    var d = time.getDate();
    return y + '-' + add0(m) + '-' + add0(d);
}
export {
    timeFormat
}
