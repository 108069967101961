const modulesFiles = require.context('./data', true, /.js$/)
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
    const moduleName = modulePath.replace(/^.\/(.*)\.js/,'$1')
    const value = modulesFiles(modulePath)
    modules[moduleName] = value.default
    return modules
}, {})
let en = {}, zh ={};
for (let key in modules) {
    en[key] = {};
    en[key] = modules[key]['en']
    zh[key] = {};
    zh[key] = modules[key]['zh']
}
export default{
    en,zh
}
