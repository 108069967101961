export default {
    zh: {
        login: '登录',
        accountLogin: '账号登录',
        account: '账号名 / 邮件地址',
        password: '密码',
        readme: '记住我',
        forgetPassword: '忘记密码',
        register: '免费注册',
        username: '用户名',
        accountRegister: '账号注册',
        passwordLength: '请输入您修改后的密码',
        setPassword: '请输入您设置的密码',
        setPasswordConfirm: '请再次输入您设置的密码',
        setNewPassword: '请输入新密码',
        setNewPasswordConfirm: '请再次输入新密码',
        organization: '请选择所属机构类型',
        usage: '请选择用途',
        email: '邮件地址',
        emailCode: '邮箱验证码',
        getEmailCode: '获取验证码',
        send: '发送',
        researchInstitutes: '科研场所',
        commercialEnterprise: '商业企业',
        governmentOrgans: '政府机构',
        personal: '个人',
        scientificResearch: '科学研究',
        commercialApplication: '商业应用',
        personalApplication: '个人应用',
        other: '其他',
        sendEmailSuccess: '邮件发送成功',
        sendEmailFail: '邮件发送失败,请检查您的邮箱地址并重试',
    },
    en: {
        login: 'Login',
        accountLogin: 'Account Login',
        account: 'Username / Email',
        password: 'Password',
        readme: 'Remember password',
        forgetPassword: 'Forget Password',
        register: 'Register',
        username: 'Username',
        accountRegister: 'Account register',
        passwordLength: 'Please enter your modified password',
        setPassword: 'Please enter your password',
        setPasswordConfirm: 'Please enter your password again',
        setNewPassword: 'Please enter a new password',
        setNewPasswordConfirm: 'Please enter a new password again',
        organization: 'Please select your organization type',
        usage: 'Please choose a usage',
        email: 'Email',
        emailCode: 'Email verification code',
        getEmailCode: 'Get email code',
        send: 'Send',
        researchInstitutes: 'Research institutes',
        commercialEnterprise: 'Commercial enterprise',
        governmentOrgans: 'Government organs',
        personal: 'Personal',
        scientificResearch: 'Scientific research',
        commercialApplication: 'Commercial application',
        personalApplication: 'Personal application',
        other: 'Other',
        sendEmailSuccess: 'Email sent successfully',
        sendEmailFail: 'Email sending failed, please check your email address and try again',
    }
}