<template>
    <div class="footer footerRoot">
        <div>{{ $t('footer.siteOwner') }}</div>
        <div>{{ $t('footer.address') }}</div>
        <div>{{ $t('footer.addressDetail') }}</div>
        <div>{{ $t('footer.address2') }}</div>
        <div>{{ $t('footer.email') }}</div>
        <div>{{ $t('footer.copyright') }}</div>
        <div>{{ $t('footer.technicalSupport') }}<a href="https://beian.miit.gov.cn/" target="_blank"
                :style="'font-weight: 100;color:' + fontColor">鲁ICP备14020872号-3</a>&emsp;<a
                href="https://beian.miit.gov.cn/" target="_blank"
                :style="'font-weight: 100;color:' + fontColor">鲁ICP备16014853号-9</a></div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            footerBackground: '#232323',
            fontColor: '#fff'
        }
    },
    watch: {
        $route: {
            handler: function (route, oldVal) {
                if (route.path === '/login') {
                    this.footerBackground = '#F3F3F3';
                    this.fontColor = '#181818'
                } else {
                    this.footerBackground = '#232323';
                    this.fontColor = '#fff'
                }
            },
            // 深度观察监听
            deep: true
        }
    },
    created() {

    }
}
</script>
<style scoped>
@import "../../public/css/footer.css";

.footerRoot {
    background-image: url("../assets/images/footer_background.png");
    background-size: cover;
    padding: 20px 0;
}
</style>
