<template>
    <div class="borderTitleRoot">
        <div class="titleText" :style="{
            color: titleColor
        }">{{ title }}</div>
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "borderTitle",
    props: {
        title: {
            type: String,
            required: true
        },
        titleColor: {
            type: String,
            default: '#0065a8'
        },
        content: {
            type: String,
            default: ''
        },
    },
    data() {
        return {

        }
    }
}
</script>

<style lang="scss">
.borderTitleRoot {
    width: 100%;
    background-color: #f7f7f7;
    font-size: 19px;
    font-weight: bold;
    border-left: 6px solid #0065a8;
    box-sizing: border-box;
    padding: 12px 16px;
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin: 25px 0;
}
</style>