export default {
    zh: {
        'title': '器件无关量子随机数信标服务（试运行）',
        'content': '声明：本网站提供的信标随机数基于量子力学非定域性原理产生，具有最好的内禀随机性，',
        'contents': '但是由于信标随机数广播公开的特性，其不能直接用于密码学保密相关应用。',
        'content1': '如果用户将信标随机数用于商业等各类活动，本网站对由此导致的一切后果概不负责。',

        'title1': '本网站为用户提供器件无关的量子随机数',
        'content2': '利用量子纠缠的物理性质，通过进行无漏洞的贝尔检验，可产生基于量子力学内禀随机性的随机数，即器件无关的量子随机数，该随机数的安全性将不依赖于采用的器件。',
        'content3': '这种随机数产生装置被认为是安全性最高的随机数产生装置。',
        'content4': '本网站所提供的器件无关量子随机数均通过NIST随机数测试标准，并且可追溯产生时间，用户可证明其在产生前具有不可预测性。',
    },
    en: {
        'title': 'Device independent quantum random number beacon service（trial run）',
        'content': 'Disclaimer: the beacon random numbers are provided based on the nonlocality principle of quantum mechanics,',
        'contents': 'which have the best inherent randomness. However, due to the broadcast commonality of beacon random numbers, they cannot be directly used in cryptography.',
        'content1': 'If beacon random numbers are used for business or other activities, we will not be responsible for all consequences.',

        'title1': 'This website provides users with device-independent quantum random numbers',
        'content2': 'Using the physical properties of quantum entanglement, through the bell test without holes, random numbers based on the inherent randomness of quantum mechanics, that is, device-independent quantum random numbers, can be generated, and the security of the random numbers will not depend on the devices used.',
        'content3': 'This random number generator is considered as the most secure random number generator.',
        'content4': 'The device-independent quantum random numbers provided by this website all pass the NIST random number test standard, and can be traced back to the generation time. Users can prove that they are unpredictable before generation.',
    }
}