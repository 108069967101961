export default {
    zh: {
        siteOwner: "济南量子技术研究院",
        address: '地址：山东省济南市高新区舜华路747号，250101',
        addressDetail: '中国科学院量子信息与量子科技创新研究院',
        address2: '地址：安徽省合肥市金寨路 96 号，230026',
        email: '联系邮箱：qrng@ustc.edu.cn',
        copyright: '版权所有：济南量子技术研究院 | 中国科学院量子信息与量子科技创新研究院',
        technicalSupport: '技术支持：英网云 备案号：',
    },
    en: {
        siteOwner: "Jinan Institute of Quantum Technology",
        address: 'Address: 747 Shunhua Road, High-tech Zone, Jinan, Shandong, 250101',
        addressDetail: 'University of Science and Technology of China',
        address2: 'Address: 96 Jinzhai Road, Hefei, Anhui, 230026',
        email: 'Email:qrng@ustc.edu.cn',
        copyright: 'Copyright: Jinan Institute of Quantum Technology | Institute of Quantum Information and Quantum Technology Innovation, Chinese Academy of Sciences'
    }
}