<template>
  <div class="root">
    <lunbo></lunbo>

    <div class="homepageContent">
      <borderTitle :title="$t('acquire.handle')" title-color="black" />
      <!--  -->
      <div class="outputValues">
        <borderTitle title="OUTPUT_VALUE(RSA)">
          <div class="value">{{ rsaValue }}</div>
        </borderTitle>
        <borderTitle title="OUTPUT_VALUE(PQC)">
          <div class="value">{{ pqcValue }}</div>
        </borderTitle>
      </div>
      <!--  -->
      <div class="bottomNav">
        <div @click="toPath(item)" v-for="(item, index) in bottomNavList" :key="index" class="bottomNavItem">
          <img :src="item.icon" class="navIcon">
          <span>{{ $t(item.nameKey) }}</span>
          <img src="../assets/images/icon_arrow_right_blue_64.png" class="arrowIcon">
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import borderTitle from '../components/borderTitle.vue';

export default {
  name: "Homepage",
  components: {
    borderTitle
  },
  data() {
    return {
      rsaValue: "",
      pqcValue: "",
      bottomNavList: [
        {
          icon: require("../assets/images/icon_radar_blue_104.png"),
          nameKey: "acquire.beaconServiceMenuTitle",
          path: "/acquire?index=2"
        },
        {
          icon: require("../assets/images/icon_menu_blue_100.png"),
          nameKey: "acquire.applicationMenuTitle",
          path: "/application?index=3"
        },
        {
          icon: require("../assets/images/icon_download_cloud_blue_96.png"),
          nameKey: "acquire.downloadServiceMenuTitle",
          path: "/downloadService?index=5"
        },
        {
          icon: require("../assets/images/icon_science_blue_96.png"),
          nameKey: "acquire.safetyAnalysisMenuTitle",
          path: "/history?index=6"
        },
      ]
    }
  },
  mounted() {
    this.flushData();
  },
  methods: {
    async flushData() {
      const { data } = await this.$post('/index/random/find', { external_id: 1 }, ".outputValues");
      this.rsaValue = (data.output_value || "").toUpperCase();
      this.pqcValue = (data.output_value_pqc || "").toUpperCase();
    },
    toPath({ path }) {
      const isUserLogin = !!this.$cookie.get("userName");

      if (path === "/member" && !isUserLogin) {
        this.$message.error(this.$t("application.loginTips"));
        return setTimeout(() => {
          this.$router.push({ path: "/login", query: { do: "login" } })
        }, 1800);
      }

      path && this.$router.push(path);
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../main.scss";

.outputValues>.borderTitleRoot {
  margin: 0;
}

.homepageContent>.borderTitleRoot {
  margin: 10px 0;
}

.root {
  width: 100%;

  .homepageContent {
    @include border-box-padding(20px $global-horizontal-padding);
    @include flex-column;
    gap: 20px;

    .outputValues {
      @include flex-row-center-center;
      @include border-box-padding(0 0 20px 0);
      gap: 20px;
      border-bottom: 2px solid rgb(231, 231, 231);

      .value {
        word-break: break-all;
      }
    }

    .bottomNav {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      width: 100%;

      .bottomNavItem {
        @include flex-row-center-center;
        gap: 3px;
        user-select: none;
        cursor: pointer;
        color: black;
        font-weight: bold;
        font-size: 20px;
        width: 100%;

        .navIcon {
          width: 35px;
        }

        .arrowIcon {
          width: 25px;
          margin-top: 1px;
        }
      }
    }
  }
}
</style>